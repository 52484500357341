@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap);
@import url('//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css');

html,
body {
  padding: 0;
  margin: 0;
  font-family: Spoqa Han Sans Neo, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  font-size: 14px;
  letter-spacing: -0.21px;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  color: #000000;
  background-color: #f7f8f9;
  line-height: 1em !important;
}

.modal-open {
  padding-right: 0 !important;
}

body:before {
  overflow: auto !important;
}

html {
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* calendar start */
/* by setting font-size, all the elements will correspond */

.responsive-calendar {
  font-size: 10px !important;
  -webkit-box-shadow: none !important;
     -moz-box-shadow: none !important;
          box-shadow: none !important;
  width: 100% !important;
  max-width: 592px !important;
  min-height: 0 !important;
  padding: 0.6em 0 !important;
}

/* .responsive-calendar .Calendar__sectionWrapper {
  min-height: 22.5em !important;
} */

.responsive-calendar .Calendar__section {
  padding: 0 2em !important;
}

.responsive-calendar .Calendar__section.-hiddenPrevious,
.responsive-calendar .Calendar__section.-hiddenNext {
  opacity: 0 !important;
}

.responsive-calendar .Calendar__weekDays {
  padding: 0 1.7em !important;
}

.responsive-calendar abbr {
  color: #374553 !important;
  text-decoration: none !important;
  cursor: auto;
}

.responsive-calendar .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  width: 100%;
  max-width: 1.2em;
  height: 2px;
  opacity: 1;
  left: calc(50% + 0.05em);
  background-color: #00a7e1;
}

.responsive-calendar .Calendar__monthYear {
  -moz-box-orient: horizontal;
  -moz-box-direction: reverse;
       flex-direction: row-reverse;
  -moz-box-pack: center;
       justify-content: center;
  width: 100%;
}

.responsive-calendar .Calendar__monthText {
  margin: 0 0 0 1em !important;
}

.responsive-calendar .Calendar__monthText,
.responsive-calendar .Calendar__yearText {
  pointer-events: none !important;
  padding: 0 !important;
}

.responsive-calendar .Calendar__yearText::after {
  content: '년';
}

.responsive-calendar .Calendar__day {
  -webkit-transform: scale(1);
     -moz-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s, -moz-transform 0.2s;
  -webkit-transition: transform 0.2s;
}

.responsive-calendar .Calendar__day:hover {
  border-radius: 24px !important;
  -webkit-transform: scale(1.1);
     -moz-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s, -moz-transform 0.2s;
  -webkit-transition: transform 0.2s;
}

.responsive-calendar .Calendar__day.dayoff:hover {
  border-radius: 24px !important;
  -webkit-transform: scale(1.1);
     -moz-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s, -moz-transform 0.2s;
  -webkit-transition: transform 0.2s;
}

.responsive-calendar .Calendar__day:focus {
  outline: none !important;
}

.responsive-calendar .Calendar__day.-selected {
  font-weight: bold;
  color: #fefefe !important;
  border-radius: 24px !important;
}

/*
.responsive-calendar .Calendar__day {
  width: 38px !important;
  min-width: 38px !important;
  margin: 0 0 !important;
}

.responsive-calendar .Calendar__header {
  padding: 16px !important;
}

.responsive-calendar .Calendar__section {
  padding: 0 2em !important;
}

.responsive-calendar .Calendar__day {
}
*/

.holiday {
  color: #ff2929 !important;
}

.dayoff {
  color: #c0c1c2 !important;
}

.dayoff::after {
  content: '휴장';
  position: absolute;
  font-size: 0.75em;
  bottom: -6px;
}

.eventday {
}

.eventday::after {
  content: '⭐️ ';
  position: absolute;
  font-size: 0.6em;
  bottom: -6px;
}

.dayoff.-selected {
  background-color: #d0d1d2 !important;
}

.dayoff.-selected:hover {
  color: #fff !important;
  background-color: #d0d1d2 !important;
}

.dayoff:hover {
  color: #c0c1c2 !important;
  background-color: #ffffff00 !important;
}

.dayoff.-selected::after {
  content: '';
}

/* calendar end */

/* media start */

.pc-wrapper {
  color: #fff;
  display: none;
}

@media (orientation: landscape) {
  /* Landscape 모드일 때 적용할 CSS */
}

@media only screen and (min-width: 1200px) {
  body:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/bg-pc-cover.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }
  #__next {
    left: calc(50% - 45px);
    -webkit-box-shadow: 0 0 10px 5px #00000011 !important;
       -moz-box-shadow: 0 0 10px 5px #00000011 !important;
            box-shadow: 0 0 10px 5px #00000011 !important;
    margin: 0 !important;
  }
  .pc-wrapper {
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
         flex-direction: column;
    -moz-box-pack: center;
         justify-content: center;
    position: fixed;
    top: 0;
    left: calc(50% - 595px);
    height: 100%;
    /* background: url('/bg-pc-horizontal.png') repeat-x; */
    z-index: 10;
  }
  .pc-bg-right {
    position: absolute;
    top: 0;
    left: 1190px;
    height: 100%;
    width: 100vw;
    /* background: url('/bg-pc-horizontal.png') repeat-x; */
  }
  .pc-bg-vertical {
    position: absolute;
    top: 760px;
    left: -236px;
    height: 100%;
    width: 200px;
    /* background: url('/bg-pc-vertical.png') center repeat-y; */
  }
  .pc-bg-corner {
    position: absolute;
    top: 560px;
    left: -236px;
    height: 216px;
    width: 236px;
    /* background: url('/bg-pc-corner.png') center no-repeat; */
  }
  .pc-branch-bi {
    cursor: pointer;
  }
  .pc-promo {
    margin: 48px 0 0 0;
    -webkit-animation: 0.5s ease-out 0.4s both smoothAppearUpY;
            animation: 0.5s ease-out 0.4s both smoothAppearUpY;
  }
  .pc-branch-info {
    margin: 120px 0 0 0;
    color: #74cbe7;
    font-weight: 300;
    font-size: 12px;
  }
  .pc-phrase {
    position: absolute;
    top: 561px;
    left: 0;
  }
  .pc-phrase div {
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
         align-items: center;
    font-weight: bold;
    font-size: 30px;
    height: 50px;
  }
  .pc-pictogram {
    position: absolute;
    top: 407px;
    left: -40px;
    width: 550px;
    height: 160px;
    /* background: url('/pc-pictogram.png') center no-repeat; */
    background-size: 75%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1199px) {
  body:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/bg-pc-cover.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }
  #__next {
    margin: 0 auto;
    -webkit-box-shadow: 0 0 10px 5px #00000011 !important;
       -moz-box-shadow: 0 0 10px 5px #00000011 !important;
            box-shadow: 0 0 10px 5px #00000011 !important;
  }
}

@media only screen and (max-width: 639px) {
  body:before {
    content: normal;
    content: initial;
  }
  #__next {
    margin: 0 auto;
  }
}

/* media end */

.nav-fill .nav-item,
.nav-fill > .nav-link {
  color: #c5c8ce;
  -moz-box-flex: 1 !important;
       flex: 1 1 !important;
}

.modal-content {
  border: none !important;
}

.terms-title {
  margin: 0 0 1em 0;
}

/* Modal */
.modal-container {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  padding: 30px 20px 20px;
}

.modal-menu {
  display: -moz-box;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.modal-menu button {
  width: 100%;
}

.modal-content-title {
  font-family: NEXON Lv1 Gothic;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2em;
}

.modal-content-text {
  font-family: NEXON Lv1 Gothic;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  line-height: 1.2em;
  margin-top: -16px;
}

.modal-status {
  text-align: center;
  padding: 10px 0;
}

.modal-status img {
  min-height: 42px;
  max-height: 80px;
}

/* misson progress */
.mission-progress-wrapper {
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-align: center;
       align-items: center;
}

.misson-progress {
  width: 300px;
  height: 300px;
}

.mission-progress-bar {
  stroke-dasharray: 295.31px, 295.31px;
  stroke-dashoffset: 0;
  -webkit-transition: stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s,
    stroke-width 0.06s ease 0.3s, opacity 0.3s ease 0s;
  transition: stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s,
    stroke-width 0.06s ease 0.3s, opacity 0.3s ease 0s;
}

.misson-progress-gauge {
  stroke-dasharray: 0px, 295.31px;
  stroke-dashoffset: 0;
  -webkit-transition-property: stroke-dashoffset, stroke-dasharray, stroke, stroke-width, opacity;
  transition-property: stroke-dashoffset, stroke-dasharray, stroke, stroke-width, opacity;
  -webkit-transition-duration: 0.3s, 0.3s, 0.3s, 0.06s;
          transition-duration: 0.3s, 0.3s, 0.3s, 0.06s;
  -webkit-transition-timing-function: ease, ease, ease, ease, ease;
          transition-timing-function: ease, ease, ease, ease, ease;
  -webkit-transition-delay: 0s, 0s, 0s, 0.3s, 0s;
          transition-delay: 0s, 0s, 0s, 0.3s, 0s;
}

.misson-progress-inner {
  stroke: rgb(82, 196, 26);
  stroke-dasharray: 0px 295.31px;
  stroke-dashoffset: 0;
  -webkit-transition-property: stroke-dashoffset, stroke-dasharray, stroke, stroke-width, opacity;
  transition-property: stroke-dashoffset, stroke-dasharray, stroke, stroke-width, opacity;
  -webkit-transition-duration: 0.4s, 0.3s, 0.3s, 0.06s;
          transition-duration: 0.4s, 0.3s, 0.3s, 0.06s;
  -webkit-transition-timing-function: ease, ease, ease, ease, ease;
          transition-timing-function: ease, ease, ease, ease, ease;
  -webkit-transition-delay: 0s, 0s, 0s, 0.3s, 0s;
          transition-delay: 0s, 0s, 0s, 0.3s, 0s;
}

.mission-fire-squence {
  position: absolute;
  width: 210px;
  height: 350px;
  background-image: url('/mission-fire.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  -webkit-animation: 0.32s steps(1, jump-start) infinite both fireAnimation;
          animation: 0.32s steps(1, jump-start) infinite both fireAnimation;
  -webkit-transform: scale(0.5);
     -moz-transform: scale(0.5);
          transform: scale(0.5);
  z-index: 1;
}

.mission-fire-aura {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background-color: #ffdd00aa;
  -webkit-transform: scale(0.5);
     -moz-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-filter: blur(60px);
          filter: blur(60px);
  z-index: 0;
}

@-webkit-keyframes fireAnimation {
  25% {
    background-position: top 0 left -210px;
  }
  50% {
    background-position: top -350px left -210px;
  }
  75% {
    background-position: top -350px left 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes fireAnimation {
  25% {
    background-position: top 0 left -210px;
  }
  50% {
    background-position: top -350px left -210px;
  }
  75% {
    background-position: top -350px left 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* common ui animations */

@-webkit-keyframes smoothAppearY {
  from {
    opacity: 0;
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes smoothAppearY {
  from {
    opacity: 0;
    -webkit-transform: translateY(-5%);
       -moz-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes smoothAppearUpY {
  from {
    opacity: 0;
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes smoothAppearUpY {
  from {
    opacity: 0;
    -webkit-transform: translateY(5%);
       -moz-transform: translateY(5%);
            transform: translateY(5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes smoothAppearX {
  from {
    opacity: 0;
    -webkit-transform: translateX(-5%);
            transform: translateX(-5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes smoothAppearX {
  from {
    opacity: 0;
    -webkit-transform: translateX(-5%);
       -moz-transform: translateX(-5%);
            transform: translateX(-5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes smoothAppearRightX {
  from {
    opacity: 0;
    -webkit-transform: translateX(5%);
            transform: translateX(5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes smoothAppearRightX {
  from {
    opacity: 0;
    -webkit-transform: translateX(5%);
       -moz-transform: translateX(5%);
            transform: translateX(5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes smoothAppearReverseXFull {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes smoothAppearReverseXFull {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%);
       -moz-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes appearY {
  from {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes appearY {
  from {
    -webkit-transform: translateY(-5%);
       -moz-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  to {
    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes slideUpY {
  from {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideUpY {
  from {
    -webkit-transform: translateY(20%);
       -moz-transform: translateY(20%);
            transform: translateY(20%);
  }
  to {
    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes slideUpFull {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideUpFull {
  from {
    -webkit-transform: translateY(100%);
       -moz-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes slideDownFull {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@keyframes slideDownFull {
  from {
    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(100%);
       -moz-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@-webkit-keyframes appearX {
  from {
    -webkit-transform: translateX(-5%);
            transform: translateX(-5%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes appearX {
  from {
    -webkit-transform: translateX(-5%);
       -moz-transform: translateX(-5%);
            transform: translateX(-5%);
  }
  to {
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideX {
  from {
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
  }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes slideX {
  from {
    -webkit-transform: translateX(-20%);
       -moz-transform: translateX(-20%);
            transform: translateX(-20%);
  }
  to {
    -webkit-transform: translateX(0%);
       -moz-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes click {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  30% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  75%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes click {
  0% {
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
  }
  30% {
    -webkit-transform: scale(0.9);
       -moz-transform: scale(0.9);
            transform: scale(0.9);
  }
  75%,
  100% {
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes tick {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tick {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(1turn);
       -moz-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@-webkit-keyframes rotateCCW {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(0.5turn);
            transform: rotate(0.5turn);
  }
}

@keyframes rotateCCW {
  from {
    -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(0.5turn);
       -moz-transform: rotate(0.5turn);
            transform: rotate(0.5turn);
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
  33% {
    -webkit-transform: scaleY(0.33);
            transform: scaleY(0.33);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0.75;
  }
  66% {
    -webkit-transform: scaleY(0.66);
            transform: scaleY(0.66);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: scaleY(0);
       -moz-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: bottom;
       -moz-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
  33% {
    -webkit-transform: scaleY(0.33);
       -moz-transform: scaleY(0.33);
            transform: scaleY(0.33);
    -webkit-transform-origin: bottom;
       -moz-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0.75;
  }
  66% {
    -webkit-transform: scaleY(0.66);
       -moz-transform: scaleY(0.66);
            transform: scaleY(0.66);
    -webkit-transform-origin: bottom;
       -moz-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
       -moz-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: bottom;
       -moz-transform-origin: bottom;
            transform-origin: bottom;
  }
}

@font-face {
  font-family: 'NEXON Lv1 Gothic';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv1 Gothic OTF.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NEXON Lv1 Gothic';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv1 Gothic OTF Bold.woff')
    format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gmarket Sans';
  src: local('Gmarket Sans Light'), local('GmarketSans-Light');
  src: url('https://script.ebay.co.kr/fonts/GmarketSansLight.woff2') format('woff2');
  src: url('https://script.ebay.co.kr/fonts/GmarketSansLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gmarket Sans';
  src: local('Gmarket Sans Medium'), local('GmarketSans-Medium');
  src: url('https://script.ebay.co.kr/fonts/GmarketSansMedium.woff2') format('woff2');
  src: url('https://script.ebay.co.kr/fonts/GmarketSansMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gmarket Sans';
  src: local('Gmarket Sans Bold'), local('GmarketSans-Bold');
  src: url('https://script.ebay.co.kr/fonts/GmarketSansBold.woff2') format('woff2');
  src: url('https://script.ebay.co.kr/fonts/GmarketSansBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

.main-carousel {
  width: 100vw;
  max-width: 640px;
  height: calc(100vw * 180 / 312);
  max-height: 360px;
  position: relative;
  overflow: hidden !important;
  background-color: #ffffff;
}
.main-carousel:after {
  content: '';
  display: block;
  background: url('/rounded-cover-bottom-white.png') no-repeat left bottom/100%;
  width: 100%;
  height: 0;
  padding-bottom: 5%;
  position: absolute;
  z-index: 1;
  bottom: 0;
}

.main-attractions-slider {
  padding: 24px 0;
  margin-bottom: 14px;
  background-color: #ffffff;
}

.slick-list {
  padding: 0 !important;
}

@-webkit-keyframes bottomDrawer {
  from {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0%, 0);
            transform: translate(0%, 0);
    opacity: 1;
  }
}

@keyframes bottomDrawer {
  from {
    -webkit-transform: translate(0, 100%);
       -moz-transform: translate(0, 100%);
            transform: translate(0, 100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0%, 0);
       -moz-transform: translate(0%, 0);
            transform: translate(0%, 0);
    opacity: 1;
  }
}

.next_image {
  height: inherit !important;
  min-height: 0 !important;
}

abbr[data-original-title],
abbr[title] {
  cursor: default !important;
}

